import jwtDecode from 'jwt-decode'

import {
  PNTOPBAR_IAM_TOKEN,
  PNTOPBAR_ID_TOKEN
} from '@/shared/constants/cache.js'
import unifiedLoginService from '@/shared/services/unifiedLoginService.js'
import { processError } from '@/shared/utils/errorHandlingUtils.js'
import { isTokenExpired } from '@/shared/utils/stringUtils'

function getDecodedUnifiedUser(idToken) {
  if (!idToken) {
    return null
  }
  const {
    username,
    exp,
    userId: id,
    given_name: firstName,
    family_name: lastName,
    email
  } = jwtDecode(idToken)
  const role = ''
  return {
    username,
    exp,
    role,
    id,
    firstName,
    lastName,
    email
  }
}

const getLocalAccessToken = () => {
  const token = localStorage.getItem(PNTOPBAR_IAM_TOKEN)
  if (token) {
    const parsed = JSON.parse(token)
    return parsed?.value
  }
  return null
}

export default {
  namespaced: true,
  state: {
    accessToken: getLocalAccessToken(), // The unified token, that uses the unified login
    idToken: localStorage.getItem(PNTOPBAR_ID_TOKEN) || null, // The user info when using unified login
    permission: null,
    user: null
  },
  getters: {
    isLoggedIn: (state) => {
      return Boolean(state.accessToken && state.idToken)
    },
    isTokenExpired: (state) => {
      const userInfo = getDecodedUnifiedUser(state.idToken)
      return isTokenExpired(userInfo?.exp)
    },
    decodedIdToken: (state) => {
      if (state.accessToken && state.idToken) {
        return getDecodedUnifiedUser(state.idToken)
      }
    },
    email: (state) => {
      return state.user?.email
    },
    country: (state) => {
      return state.user?.azure_usage_location
    },
    fullName: (state) => {
      const userInfo = getDecodedUnifiedUser(state.idToken)
      return `${userInfo?.firstName} ${userInfo?.lastName}`
    },
    isPostnordEmail: (state) => {
      return (
        getDecodedUnifiedUser(state.idToken)?.email?.split('@').pop() ===
        'postnord.com'
      )
    },
    accessToken: (state) => state.accessToken
  },
  actions: {
    login: () => {
      window.pnTopbar.login()
    },
    logout: () => {
      window.pnTopbar.logout()
    },
    getPermission: async ({ state, dispatch }) => {
      state.loading = true
      try {
        const accessToken = state.accessToken
        const response = await unifiedLoginService.getPermission(accessToken)
        state.permission =
          response.data && !isNaN(response.data) ? response.data : -1
      } catch (error) {
        processError(error, dispatch)
      }
      state.loading = false
    },
    setUnifiedStoreState: ({ state }) => {
      const currentToken = state.accessToken
      const currentIdToken = state.idToken
      if (
        currentToken !== window.pnTopbar?.access_token &&
        currentIdToken !== window.pnTopbar?.id_token
      ) {
        state.accessToken = window.pnTopbar?.access_token
        state.idToken = window.pnTopbar?.id_token
      }
      state.user = window.pnTopbar?.user
    }
  }
}
