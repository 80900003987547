import cashRegister from '@/modules/DailyBalance/store/cashRegister'
import deliverylists from '@/modules/DeliveryAgreements/DeliveryList/store/deliverylists'
import deliveryAgreements from '@/modules/DeliveryAgreements/store/deliveryAgreements'
import fallback from '@/modules/Fallback/store/fallback'
import forecast from '@/modules/Forecast/store/forecast'
import { RETAIL_LANGUAGE } from '@postnord/retail-backoffice-components/dist'
import { createLogger, createStore } from 'vuex'
import features from './features'
import retail from './retail'
import toaster from './toaster'
import unified from './unified'

const store = createStore({
  plugins: [createLogger()],
  state: {
    lang: localStorage.getItem(RETAIL_LANGUAGE) || 'en',
    env: process.env.NODE_ENV,
    error: '',
    loading: false
  },
  getters: {
    currentLang: (state) => {
      return state.lang
    },
    isProduction: (state) => {
      return state.env === 'production'
    },
    isUnifiedSession: (state) => {
      return state.activeProfile.type === 'unified'
    },
    isServicepointSession: (state) => {
      return state.activeProfile.type === 'servicepoint'
    }
  },
  actions: {
    setLocalizationState({ rootState }, language) {
      rootState.lang = language
      localStorage.setItem(RETAIL_LANGUAGE, language)
    }
  },
  modules: {
    retail,
    features,
    unified,
    toaster,
    cashRegister,
    fallback,
    forecast,
    deliveryAgreements,
    deliverylists
  }
})

export default store
