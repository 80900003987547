const PERMISSION_TYPES = {
  guest: 0,
  read: 1,
  write: 2,
  admin: 3
}
const origin = window.location.origin

export const getLocalRetailItems = () => {
  return [
    {
      name: 'Start',
      url: origin,
      accessList: ['rp.partner-landing.read'],
      meta: {
        id: 'backoffice',
        permission: PERMISSION_TYPES.guest,
        icon: 'home',
        illustration: 'postnord_customer_service'
      }
    },
    {
      name: 'Items Backoffice',
      url: `${origin}/items/`,
      accessList: ['rp.items-backoffice.read', 'rp.items-backoffice.write'],
      meta: {
        id: 'itemsbackoffice',
        permission: PERMISSION_TYPES.read,
        icon: 'postcard',
        illustration: 'customer_service'
      }
    },
    {
      name: 'Search Delivery Agreements',
      accessList: ['rp.delivery-agreements.read'],
      url: `${origin}/delivery-agreements/`,
      meta: {
        id: 'deliveryagreements',
        permission: PERMISSION_TYPES.read,
        icon: 'search',
        illustration: 'person_computer'
      }
    },
    {
      name: 'Retail User Administration',
      accessList: [
        'rp.user-administration.read',
        'rp.user-administration.write'
      ],
      url: `${origin}/user-admin/`,
      meta: {
        id: 'useradmin',
        permission: PERMISSION_TYPES.read,
        icon: 'users',
        illustration: 'company_settings'
      }
    },
    {
      name: 'Retail Label Test',
      accessList: ['rp.label-test.read'],
      url: `${origin}/test-tool/`,
      meta: {
        id: 'testool',
        permission: PERMISSION_TYPES.read,
        icon: 'barcode',
        illustration: 'notifications'
      }
    },
    {
      name: 'Retail Emergency Fallback - Superuser',
      accessList: ['rp.emergency-fallback.read'],
      url: `${origin}/fallback-superuser/`,
      meta: {
        id: 'fallbacksuperuser',
        permission: PERMISSION_TYPES.read,
        icon: 'alert_exclamation_circle',
        illustration: 'newsletter_map_calendar'
      }
    },
    {
      name: 'Capabilities Backoffice',
      accessList: ['rp.capabilities.read', 'rp.capabilities.write'],
      url: `${origin}/capabilities/`,
      meta: {
        id: 'capabilities',
        permission: PERMISSION_TYPES.admin,
        icon: 'settings',
        illustration: 'dashboard'
      }
    },
    {
      name: 'Whitelisting Backoffice',
      accessList: ['rp.whitelisting.read', 'rp.whitelisting.write'],
      url: `${origin}/whitelists/`,
      meta: {
        id: 'whitelists',
        permission: PERMISSION_TYPES.admin,
        icon: 'list',
        illustration: 'claims'
      }
    },
    {
      name: 'Retail Trumpet',
      accessList: ['rp.trumpet.read', 'rp.trumpet.write'],
      url: `${origin}/trumpet/`,
      meta: {
        id: 'trumpet',
        permission: PERMISSION_TYPES.admin,
        icon: 'bullhorn',
        illustration: 'mobile_notification'
      }
    },
    {
      name: 'Compensation management',
      accessList: [
        'rp.compensation-management.read',
        'rp.compensation-management.write'
      ],
      url: `${origin}/compensation-management/`,
      meta: {
        id: 'compensationmanagement',
        permission: PERMISSION_TYPES.write,
        icon: 'box_label',
        illustration: 'hand_paper_money'
      }
    },
    {
      name: 'Compensation invoices',
      accessList: [
        'rp.compensation-invoices.read',
        'rp.compensation-invoices.write'
      ],
      url: `${origin}/compensation-invoices/`,
      meta: {
        id: 'compensationinvoices',
        permission: PERMISSION_TYPES.write,
        icon: 'box_plus',
        illustration: 'invoices'
      }
    },
    {
      name: 'Partner web',
      accessList: ['rp.partner-web.read'],
      description: 'APP.PARTNER_WEB_DESCRIPTION',
      url: process.env.VUE_APP_PARTNER_WEB_URL,
      meta: {
        id: 'partnerweb',
        permission: PERMISSION_TYPES.read,
        icon: 'credit_card',
        illustration: 'file_paper_arrow',
        isExternal: true
      }
    },
    {
      name: 'Kvittenslistewebben',
      description: 'APP.KVITTENS_WEB_DESCRIPTION',
      url: process.env.VUE_APP_KVITTENS_WEB_URL,
      accessList: ['rp.kvittensweb.read'],
      meta: {
        id: 'kvittensweb',
        permission: PERMISSION_TYPES.read,
        icon: 'pn_document',
        illustration: 'track_and_trace',
        isExternal: true
      }
    }
  ]
}
