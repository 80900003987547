import jwtDecode from 'jwt-decode'

import { RETAIL_IAM_TOKEN } from '@/shared/constants/cache'
import retailLoginService from '@/shared/services/retailLoginService'
import retailUtilsService from '@/shared/services/retailUtilsService.js'
import { processError } from '@/shared/utils/errorHandlingUtils.js'
import { isTokenExpired } from '@/shared/utils/stringUtils'
import { RETAIL_JWT_TOKEN } from '@postnord/retail-backoffice-components/dist'
import i18n from '../i18n/i18n.js'

function getDecodedServicepointUser(idToken) {
  if (!idToken) {
    return null
  }
  const { user: username, exp, role, sourceid: id } = jwtDecode(idToken)
  return {
    username,
    exp,
    role,
    id
  }
}

export default {
  namespaced: true,
  state: {
    authorization: localStorage.getItem(RETAIL_JWT_TOKEN) || null, // The retail JWT code
    ssid: '',
    capability: null,
    iamToken: null, // The temporary IAM token use to access external service like E-shop and Baseregister
    servicepoint: null,
    market: null
  },
  getters: {
    isLoggedIn: (state) => {
      return Boolean(state.authorization)
    },
    countryCode: (state) => {
      const userInfo = getDecodedServicepointUser(state.authorization)
      return userInfo?.id?.substring(0, 2) || null
    },
    isTokenExpired: (state) => {
      const userInfo = getDecodedServicepointUser(state.authorization)
      return isTokenExpired(userInfo?.exp)
    },
    decodedIdToken: (state) => {
      return getDecodedServicepointUser(state.authorization)
    },
    acceptCashPayments: (state) => {
      return state.capability?.cashier?.acceptCashPayments
    },
    currentMarket: (state) => {
      return state.market || window.pnTopbar.market
    }
  },

  actions: {
    login: async ({ rootState, dispatch, commit }, payload) => {
      const { ssid, username, password } = payload
      rootState.loading = true
      rootState.retail.ssid = ssid
      try {
        const response = await retailLoginService.login(
          ssid,
          username,
          password
        )
        const token = response?.data?.jwttoken?.token || ''
        if (token) {
          rootState.retail.authorization = token
          commit('didLogin')
        } else {
          processError('Unable to get authorization', dispatch)
        }
      } catch (error) {
        console.log('Error:', error.response)
        let overridenError = i18n.global.t('APP.INVALID_LOGIN_MESSAGE')

        if (error.response && error.response.status >= 500) {
          overridenError = i18n.global.t('APP.LOGIN_5XX_MESSAGE')
        }
        processError(overridenError, dispatch)
      }
      rootState.loading = false
    },

    logout: ({ commit }) => {
      commit('didLogout')
    },
    exchangeToken: async ({ rootState, dispatch, commit }) => {
      rootState.loading = true
      try {
        const response = await retailLoginService.exchangeToken()
        if (response.status === 200 && response.data?.access_token) {
          commit('didGetIamToken', response.data)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    clearIamToken: ({ commit }) => {
      commit('didClearIamToken')
    },
    getPdf: async ({ rootState, dispatch, commit }, payload) => {
      rootState.loading = true
      try {
        const response = await retailUtilsService.getPdf(payload)
        if (response.status === 200) {
          commit('didGetPdf', response.data)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    getCapability: async ({ rootState, dispatch, commit }) => {
      rootState.loading = true
      try {
        const res = await retailUtilsService.getCapability()
        if (res.data.response) {
          rootState.retail.capability = res.data.response.capability
          commit('didGetCapability')
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    setMarket({ commit }, market) {
      commit('didSetMarket', market)
    }
  },
  mutations: {
    didLogin(state) {
      localStorage.setItem(RETAIL_JWT_TOKEN, state.authorization)
    },
    didLogout(state) {
      state.ssid = ''
      state.authorization = ''
      state.capability = null
      localStorage.removeItem(RETAIL_JWT_TOKEN)
    },
    didGetIamToken(state, data) {
      state.iamToken = data.access_token
      localStorage.setItem(RETAIL_IAM_TOKEN, state.iamToken)
    },
    didClearIamToken(state) {
      state.iamToken = null
      localStorage.removeItem(RETAIL_IAM_TOKEN)
    },
    didGetPdf() {
      // for notifications only
    },
    didGetCapability() {
      // for notifications only
    },
    didSetMarket(state, market) {
      state.market = market
    }
  }
}
