import { GrowthBook } from '@growthbook/growthbook'

const growthbook = new GrowthBook({
  apiHost: process.env.VUE_APP_GROWTHBOOK_HOST,
  clientKey: process.env.VUE_APP_GROWTHBOOK_APP_KEY,
  enableDevMode: true
})
export default {
  namespaced: true,
  state: {
    isCompensationEnabled: false,
    isAccesslistEnabled: false
  },
  mutations: {
    isCompensationEnabled(state, status) {
      state.isCompensationEnabled = status
    },
    isAccesslistEnabled(state, status) {
      state.isAccesslistEnabled = status
    }
  },
  getters: {
    isCompensationEnabled(state) {
      return state.isCompensationEnabled
    },
    isAccesslistEnabled(state) {
      return state.isAccesslistEnabled
    }
  },
  actions: {
    async growthbookInit(context) {
      const email = context.rootGetters['unified/email']
      const country = context.rootGetters['unified/country']

      growthbook.setAttributes({
        id: email,
        country
      })
      await growthbook.init({ streaming: false })
      const isCompensationEnabled = growthbook.isOn(
        'retail-compensation-agreements'
      )
      const isAccesslistEnabled = growthbook.isOn('retail-access-lists')

      context.commit('isCompensationEnabled', isCompensationEnabled)
      context.commit('isAccesslistEnabled', isAccesslistEnabled)
    }
  }
}
