import i18n from '@/i18n/i18n.js'
import * as iconsModule from '@/shared/constants/icons'
import * as illustrationsModule from '@/shared/constants/illustrations'
import jwtDecode from 'jwt-decode'

export const getTokenProps = () => {
  const accessToken = localStorage.id_token
  if (!accessToken) {
    return null
  }
  return jwtDecode(accessToken)
}

export const translateMenuName = (menu) => {
  const { t } = i18n.global
  if (menu?.name?.includes('.')) {
    return t(menu.name)
  } else {
    return menu.name
  }
}

export const addSidemenuMeta = (item) => {
  if (typeof item.meta.icon == 'string' && iconsModule[item.meta.icon]) {
    item.meta.icon = iconsModule[item.meta.icon]
  }

  if (
    typeof item.meta.illustration == 'string' &&
    illustrationsModule[item.meta.illustration]
  ) {
    item.meta.illustration = illustrationsModule[item.meta.illustration]
  }

  item.name = translateMenuName(item)
  return item
}
